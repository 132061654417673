import React from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
  PinterestShareButton,
} from "react-share";
import { EmailIcon, FacebookIcon, TwitterIcon ,PinterestIcon} from "react-share";

const Share = () => {
  const currentPageURL = window.location.href;
  return (
    <div>
      <FacebookShareButton className="shareButtons" url={currentPageURL}>
        <FacebookIcon size={26} round={true} />
      
      </FacebookShareButton>

      <EmailShareButton className="shareButtons" url={currentPageURL}>
        <EmailIcon size={26} round={true} />
        
      </EmailShareButton>
      <TwitterShareButton className="shareButtons" url={currentPageURL}>
        <TwitterIcon size={26} round={true} />
     
      </TwitterShareButton>
 <PinterestShareButton className="shareButtons" url={currentPageURL} media>
        <PinterestIcon size={26} round={true}/>
      </PinterestShareButton> 
    </div>
  );
};

export default Share;
