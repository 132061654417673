import axios from "axios";
import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(
    JSON.parse(localStorage.getItem("user") || null)
  );
 
  
  const login = async (inputs) => {
    const res = await axios.post(
      "https://blogenapi.dasinterior.com.tr/api/auth/giris-yap",
      inputs
    );
    if (res.status === 200) {
      setCurrentUser(res.data);
      return true;
    } else {
      return false;
  }
  };

  const register = async (inputs) => {
    const res = await axios.post(
      "https://blogenapi.dasinterior.com.tr/api/auth/uye-ol",
      inputs
    );
    if (res.status === 200) {
      return true;
    } else {
      return false;
    }
  };

  const logout = async () => {
    if(currentUser) {
      await axios.post("https://blogenapi.dasinterior.com.tr/api/auth/cikis-yap");
      setCurrentUser(null);
      console.log("logout çalıştı")
    }
    
  };

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(currentUser));
  }, [currentUser]);
  


  return (
    <AuthContext.Provider value={{ currentUser, register , login, logout }}>
      {children}
    </AuthContext.Provider>
  );

  // index.js'te <App/>'yi <AuthContextProvider/> ile sarmala
};
