import React, { useContext, useState } from "react";
import Navbar from "../components/Navbar";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext, AuthContextProvider } from "../context/authContext";
import "./login.css";import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import alertify from "alertifyjs";

const Login = () => {
  const [inputs, setInputs] = useState({
    fullName: "",
    password: "",
  });

  const [err, setErr] = useState(null);

  const navigate = useNavigate();

  const {login} = useContext(AuthContext)

  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // butona tıkladığında sayfayı refleshlememesi için
    try {
    
      var r = await login(inputs) 
      if(r){
        alertify.success("Logged In");
        navigate("/");
      }
      
    } catch (error) {
      alertify.error("Check your informations")
      console.log(error);
    }
  };

  return (
    <div>
      <Navbar />
      <h1>Login</h1>
      <form className="form">
        <input
          required
          name="fullName"
          type="text"
          placeholder="Username"
          onChange={handleChange}
        ></input>

        <input
          required
          name="password"
          type="password"
          placeholder="Password"
          onChange={handleChange}
        ></input>
        <button onClick={handleSubmit} className="btn btn-three">
          Login
        </button>
         { err && <p>{err}</p>} 
        <span>
          Don't have an account? <a href="/uye-ol">Sign In</a>
        </span>
      
      </form>
    </div>
  );
};

export default Login;
