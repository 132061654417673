import React, { useContext, useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../context/authContext";
import "./single.css";
import Comments from "../components/Comments";
import Share from "../components/Share";
import alertify from "alertifyjs";

const Single = () => {
  const navigate = useNavigate();

  const [post, setPost] = useState({});

  const location = useLocation();

  const postId = location.pathname.split("/")[2];

  const { currentUser } = useContext(AuthContext);
  const [commentOpen, setCommentOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `https://blogenapi.dasinterior.com.tr/api/posts/${postId}`
        );
        setPost(res.data);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, [postId]);

  const handleDelete = async () => {
    try {
      await axios.delete(`https://blogenapi.dasinterior.com.tr/api/posts/${postId}`);
      alertify.success("Post silindi");
      navigate("/");
    } catch (err) {
      console.log(err);
    }
  };
  const getText = (html) => {
    try {
      const doc = new DOMParser().parseFromString(html, "text/html");
      return doc.body.innerHTML;
    } catch (error) {
      console.error("Hata oluştu:", error);
      return null;
    }
  };

  return (
    <div>
      <Navbar />

      <div className="info">
        <p className="singlecategory">
               {post.category}    {" "}
        </p>
        <h1 className="postTitlesingle">{post.title}</h1>
      </div>

     
        <div className="content">
          {post.image != undefined ? (
            <img
              src={`https://blogenapi.dasinterior.com.tr/api/img/${post.image}`}
              alt="post image"
            ></img>
          ) : (
            ""
          )}
        </div>

        <p
          className="postdesc"
          dangerouslySetInnerHTML={{ __html: getText(post.description) }}
        />
    
      {currentUser?.id === 4 && (
        <div className="postEditDelete">
          <div className="edit">
            <Link to={`/post-olustur?edit=?`} state={post}>
              <img
                width="30"
                height="30"
                src="https://img.icons8.com/ios-glyphs/30/edit--v1.png"
                alt="edit"
              />
              <span>Edit</span>
            </Link>
          </div>
          <div className="delete">
            <Link onClick={handleDelete}>
              <img
                width="30"
                height="30"
                src="https://img.icons8.com/ios-glyphs/30/filled-trash.png"
                alt="trash"
              />
              <span>Delete</span>
            </Link>
          </div>
        </div>
      )}

      <div className="commentsSection">{<Comments postId={postId} />}</div>
      <div className="shareSection">
        {" "}
        <Share></Share>
      </div>
      <Footer />
    </div>
  );
};

export default Single;
