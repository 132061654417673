import React, { useContext, useState } from "react";
import Link, { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import "./register.css";
import { AuthContext } from "../context/authContext";
import alertify from "alertifyjs";



const Register = () => {
  const [inputs, setInputs] = useState({
    fullName: "",
    email: "",
    password: "",
  });

  const [err, setErr] = useState(null);

  const {register} = useContext(AuthContext)
  const navigate = useNavigate();

  const handleChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
    };

  const handleSubmit = async (e) => {
    e.preventDefault(); // butona tıkladığında sayfayı refleshlememsi için

    try {
      var r = await register(inputs)
      if(r){
       alertify.success("Signed In")
        navigate("/giris-yap"); 
      }
     
    } catch (error) {
      alertify.error("Check your informations")
      console.log(error);
    }
  };

  return (
    <div>
      <Navbar />
      <h1>Sign In</h1>
      <form>
        <input
          className="registerInputs"
          required
          name="fullName"
          type="text"
          placeholder="Username"
          onChange={handleChange}
        ></input>
        <input
          className="registerInputs"
          required
          name="email"
          type="email"
          placeholder="Email"
          onChange={handleChange}
        ></input>
        <input
          className="registerInputs"
          required
          name="password"
          type="password"
          placeholder="Password"
          onChange={handleChange}
        ></input>
        <button className="btn btn-three" onClick={handleSubmit}>
         Sign In
        </button>
        {/* <div className="loginOption">
          ya da 
        </div> */}
        { err && <p>{err}</p>} 
        <span>
          Already have an account?<a href="/giris-yap">Login</a>
        </span>

        
      </form>
    </div>
  );
};

export default Register;
