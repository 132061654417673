import React from 'react'
import "./styles.css"

const Footer = () => {
  return (
    <div><footer className="footer" id="footer">
    <div className="social">
      <a target="_blank" href=""><i className="fa-brands fa-youtube"></i></a>
      <a target="_blank" href="https://www.instagram.com/das.interiordesigns/"
        ><i className="fa-brands fa-instagram"></i>
     </a>
      <a
        target="_blank"
        href="https://www.facebook.com/people/dasinteriordesign/100090557134092/?mibextid=LQQJ4d"
        ><i className="fa-brands fa-facebook"></i>
      </a>
      <a target="_blank" href="https://tr.pinterest.com/dasinteriordesigns/"
        ><i className="fa-brands fa-pinterest"></i>
      </a>
    </div>
    <p>© Copyright das interior design</p>
  </footer></div>
  )
}

export default Footer