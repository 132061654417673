// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form input{
          border: none;
          border-bottom: 1px solid gray;
          background: inherit;
          width: 300px;
          height: 50px;
          margin: 10px;
          font-size: 20px;
}`, "",{"version":3,"sources":["webpack://./src/pages/login.css"],"names":[],"mappings":"AAAA;UACU,YAAY;UACZ,6BAA6B;UAC7B,mBAAmB;UACnB,YAAY;UACZ,YAAY;UACZ,YAAY;UACZ,eAAe;AACzB","sourcesContent":[".form input{\r\n          border: none;\r\n          border-bottom: 1px solid gray;\r\n          background: inherit;\r\n          width: 300px;\r\n          height: 50px;\r\n          margin: 10px;\r\n          font-size: 20px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
