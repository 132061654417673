import React from "react";
import { Link } from "react-router-dom";

const Categories = () => {
  return (
    <div className="categories">
      <ul class="nav justify-content-center">
        <li className="nav-item">
          <Link to="/">All Posts</Link>
        </li>
        <li className="nav-item">
          <Link to="/?category=Kitchen-Bathroom">Kitchen-Bathroom</Link>
        </li>
        <li className="nav-item">
          <Link to="/?category=Garden">Garden</Link>
        </li>
        <li className="nav-item">
          <Link to="/?category=Sustainability">Sustainability</Link>
        </li>
        <li className="nav-item">
          <Link to="/?category=Decoration Tips">Decoration Tips</Link>
        </li>
        <li className="nav-item">
          <Link to="/?category=Exclusive to Didim">Exclusive to Didim</Link>
        </li>
      </ul>
    </div>
  );
};

export default Categories;
