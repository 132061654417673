import React, { useContext, useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import "./write.css";
import alertify from "alertifyjs";
import { AuthContext } from "../context/authContext";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Link } from "react-router-dom";

axios.defaults.headers.common["x-auth-token"] =
  localStorage.getItem("x-auth-token");

const Write = () => {
  const { currentUser } = useContext(AuthContext);
  const state = useLocation().state;
  const [value, setValue] = useState(state?.description || "");

  const [title, setTitle] = useState(state?.title || "");

  const [category, setCategory] = useState(state?.category || null);

  const [file, setFile] = useState(null);
  const navigate = useNavigate();
  const upload = async () => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      console.log(file);
      console.log(file.name);

      const res = await axios.post("https://blogenapi.dasinterior.com.tr/api/img", formData);
      console.log(res);
      console.log(res.data);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let imgUrl = "";
    if (file) {
      imgUrl = await upload();
    }

    try {
      const token = currentUser.token;
      state
        ? await axios.put(`https://blogenapi.dasinterior.com.tr/api/posts/${state.id}`, {
            title,
            description: value,
            category,
            image: file ? imgUrl : "",
            token: token,
          })
        : await axios.post(`https://blogenapi.dasinterior.com.tr/api/posts`, {
            title,
            description: value,
            category,
            image: file ? imgUrl : "",
            date: moment(Date.now()).format("YYYY-MM-DD"),
            token: token,
          });
      navigate("/");
    } catch (error) {
      console.log(error);
      throw error;
    }
  };



  return (
    <div>
      <Navbar />
      <form action="/upload" method="post" encType="multipart/form-data">
        <div className="content">
          <input
            className="titleInput"
            value={title}
            type="text"
            placeholder="Başlık"
            onChange={(e) => setTitle(e.target.value)}
          ></input>
          <div className="textEditor">
            <ReactQuill
              className="editorContainer"
              theme="snow"
              value={value}
              onChange={setValue}
            ></ReactQuill>
          </div>
        </div>

        <div className="menu">
          <input
            className="fileChoose"
            type="file"
            name=""
            id="file"
            onChange={(e) => setFile(e.target.files[0])}
          ></input>
          <label htmlFor="file">Görsel yükle</label>

          <div className="left">
            {file && (
              <img
                className="uploadedImg"
                src={URL.createObjectURL(file)}
              ></img>
            )}
          </div>

          <div className="item">
            <h2>Kategori</h2>

            <div className="category">
              <input
                type="radio"
                checked={category === "Kitchen-Bathroom"}
                name="cat"
                value="Kitchen-Bathroom"
                id="KitchenBathroom"
                onChange={(e) => setCategory(e.target.value)}
              ></input>
              <label htmlFor="Kitchen-Bathroom">Kitchen-Bathroom</label>
            </div>
            <div className="category">
              <input
                type="radio"
                checked={category === "Garden"}
                name="cat"
                value="Garden"
                id="Garden"
                onChange={(e) => setCategory(e.target.value)}
              ></input>
              <label htmlFor="Garden">Garden</label>
            </div>

            <div className="category">
              <input
                type="radio"
                checked={category === "Sustainability"}
                name="cat"
                value="Sustainability"
                id="Sustainability"
                onChange={(e) => setCategory(e.target.value)}
              ></input>
              <label htmlFor="Sustainability">Sustainability</label>
            </div>
            <div className="category">
              <input
                type="radio"
                checked={category === "Decoration Tips"}
                name="cat"
                value="Decoration Tips"
                id="DecorationTips"
                onChange={(e) => setCategory(e.target.value)}
              ></input>
              <label htmlFor="Decoration Tips">Decoration Tips</label>
            </div>
            <div className="category">
              <input
                type="radio"
                checked={category === "Exclusive to Didim"}
                name="cat"
                value="Exclusive to Didim"
                id="ExclusivetoDidim"
                onChange={(e) => setCategory(e.target.value)}
              ></input>
              <label htmlFor="Exclusive to Didim">Exclusive to Didim</label>
            </div>
          </div>
        

          <div className="buttons">
            <button
              type="submit"
              className="btn btn-three"
              onClick={handleSubmit}
            >
              Yayınla
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Write;
