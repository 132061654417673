import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/authContext";
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import moment from "moment";
import axios from "axios";
import alertify from "alertifyjs";

const Comments = ({ postId }) => {
  const [desc, setDesc] = useState("");
  const queryClient = useQueryClient();

  const { isLoading, error, data } = useQuery(["comments", postId], () =>
    axios
      .get(`https://blogenapi.dasinterior.com.tr/api/comments/${postId}`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log(error);
      })
  );

  const handleChange = (e) => {
    setDesc(e.target.value);
  };

  const { currentUser } = useContext(AuthContext);
  const token = currentUser?.token;

  const mutation = useMutation(
    (newComment) => {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`, // Token'ı Authorization başlığına ekliyoruz
        },
      };
      return axios.post(
        `https://blogenapi.dasinterior.com.tr/api/comments/${postId}`,
        newComment,
        config
      );
    },
    {
      onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries(["comments"]);
      },
    }
  );

  const handleClick = async (e) => {
    e.preventDefault();

    const newComment = {
      desc,
      postId,
    };

    mutation.mutate(newComment);
    setDesc("");
  };

  const deleteComment = async (id) => {
    try {
      const response = await axios.delete(
        `https://blogenapi.dasinterior.com.tr/api/comments/${postId}/${id}`,
        {
          headers: { Authorization: token },
        }
      );
      queryClient.invalidateQueries(["comments"])
      alertify.success("Comment deleted")
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="comments">
      <div className="write">
        <textarea
          className="makeComment"
          type="text"
          placeholder="Write a comment"
          value={desc}
          onChange={handleChange}
        ></textarea>
        <button onClick={handleClick} className="btn btn-three">
          Send
        </button>
      </div>

      {isLoading ? (
        "Loading..."
      ) : data && data.length > 0 ? (
        data.map((comment) => (
          <div className="commentInfo" key={comment.id}>
            {" "}
 
          

            <div className="commentHeader">
              <span className="fullname">{comment.fullName}</span>
              <span className="date">
              {moment(comment.createdAt).format("DD.MM.YYYY")}
            </span>
            </div>
            <p className="decs"> {comment.desc} </p>
            <p className="text">{comment.text}</p>
           
            <div className="delete" onClick={() => deleteComment(comment.id)}>
              <img
                width="30"
                height="30"
                src="https://img.icons8.com/ios-glyphs/30/filled-trash.png"
                alt="trash"
              />
            </div>
            {/* <hr></hr> */}
          </div>
        ))
      ) : (
        <p>No comments yet.</p>
      )}
    </div>
  );
};

export default Comments;
