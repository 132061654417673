import React, { useContext, useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import "./home.css";
import Categories from "../components/Categories";
import { AuthContext } from "../context/authContext";
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import alertify from "alertifyjs";

const Home = () => {
  const [posts, setPosts] = useState([]);
  const location = useLocation();
  const [likedByCurrentUser, setLikedByCurrentUser] = useState(false);
  const category = useLocation().search;
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const postId = location.pathname.split("/")[2];
  const [cuurentPage, setCurrentPage] = useState(1);
  const postsPerPage = 7;
  const lastIndex = cuurentPage * postsPerPage;
  const firstIndex = lastIndex - postsPerPage;
  const records = posts.slice(firstIndex, lastIndex);
  const nPage = Math.ceil(posts.length / postsPerPage);
  const numbers = [...Array(nPage + 1).keys()].slice(1);

  const { isLoading, error, data } = useQuery(["likes"], () =>
    axios.get(`https://blogenapi.dasinterior.com.tr/api/likes/`).then((res) => res.data)
  );
  // kategori filtrelemesi
  useEffect(() => {
    const fetchData = async () => {
      console.log("use effect home çalıştı");
      try {
        // console.log("category", category);
        const res = await axios.get(
          `https://blogenapi.dasinterior.com.tr/api/posts/${category}`
        );
        console.log("res", res.data);
        setPosts(res.data);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, [category]);

  // metin kısaltma
  const excerpt = (str, count) => {
    if (str && str.length > count) {
      str = str.substring(0, count);
      str = str.substring(0, Math.min(str.length, str.lastIndexOf(" ")));

    }

    return str;
  };
  const getText = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent;
  };

  //başlığı büyük harfe çevirme
  function convertToUpperCase(text) {
    return text.toUpperCase();
  }
  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://blogenapi.dasinterior.com.tr/api/posts/${id}`, {
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      });
      alertify.success("Post silindi");
      navigate("/");
    } catch (err) {
      alertify.error("Post silinemedi tekrar deneyin");
      console.log(err);
    }
  };

  const queryClient = useQueryClient();

  const mutation = useMutation(
    (liked) => {
      if (liked)
        return axios.delete(`https://blogenapi.dasinterior.com.tr/api/likes?postId=` + postId);
      return axios.post(`https://blogenapi.dasinterior.com.tr/api/`, {
        postId: postId,
      });
    },
    {
      onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries(["likes"]);
      },
    }
  );

  const [likeCount, setLikeCount] = useState(0);
  const handleLike = async (id) => {
    try {
      // Beğeni işlemini gerçekleştiren API isteği
      const response = await axios.post(
        `https://blogenapi.dasinterior.com.tr/api/likes/${id}`,
        { commentId: id },
        {
          headers: {
            Authorization: `Bearer ${currentUser.token}`,
          },
        }
      );
      queryClient.invalidateQueries(["likes"]);
    } catch (error) {
      // Hata durumunda yapılacak işlemler
      console.log(error);
    }
  };

  const handleDeleteLike = async (id) => {
    try {
      // Beğeni işlemini gerçekleştiren API isteği
      const response = await axios.delete(
        `https://blogenapi.dasinterior.com.tr/api/likes/${id}`,
        {
          headers: {
            Authorization: `Bearer ${currentUser.token}`,
          },
        }
      );
      queryClient.invalidateQueries(["likes"]);
    } catch (err) {
      console.log(err);
    }
  };
  //pagination
  function prePage() {
    if (cuurentPage !== firstIndex) {
      setCurrentPage(cuurentPage - 1);
    }
  }
  function nextPage() {
    if (cuurentPage !== nPage) {
      setCurrentPage(cuurentPage + 1);
    }
  }
  function changePage(id) {
    setCurrentPage(id);
  }



  return (
    <div>
      <Navbar /> <div></div>
      <Categories />
      <div className="posts">
        {records.map((post) => (
          <div className="homepost" key={post.id}>
            {" "}
            <div className="postalign">
              <div className="postImageHome">
                <Link to={`/post/${post.id}`}>
                  <img
                    className="image"
                    src={`https://blogenapi.dasinterior.com.tr/api/img/${post.image}`}
                    alt="postImg"
                  ></img>
                </Link>
              </div>

              <div className="titledesc">
                {" "}
                <p className="homecategory">
                  &nbsp;&nbsp;{post.category}&nbsp;&nbsp;
                </p>
                <div className="homePostTitle">
                  <Link to={`/post/${post.id}`}>
                    <h1 className="postTitle">{post.title}</h1>
                  </Link>
                </div>
                <div className="homePostDesc">
                  <Link to={`/post/${post.id}`}>
                    {" "}
                    <p className="postDesc">
                      {excerpt(getText(post.description), 320)}{" "}
                      <span className="readMore">Read more...</span>
                    </p>
                  </Link>
                </div>
                <div className="dateLikeSection">
                  <div className="date">
                    {new Date(post.date).toLocaleDateString("tr-TR")} / Serhan
                    Durmaz
                    {/* Şimdilik tek post yazarı sonradan değişir */}
                  </div>{" "}
                  {/* <div className="seeMoreBtn">
                <button className="btn ">
                  {" "}
                  <Link to={`/post/${post.id}`}>Daha Fazla</Link>{" "}
                </button>
              </div>  */}
                  {currentUser && data && (
                    <div className="likes">
                      <div className="likeHeart">
                        {data.some(
                          (item) =>
                            item.postId == post.id &&
                            item.likedUsers.includes(currentUser.id.toString())
                        ) ? (
                          <img
                            onClick={() => handleDeleteLike(post.id)}
                            width="20"
                            height="20"
                            src="https://img.icons8.com/color/48/like--v1.png"
                            alt="like--v1"
                          />
                        ) : (
                          <img
                            onClick={() => handleLike(post.id)}
                            width="20"
                            height="20"
                            src="https://img.icons8.com/ios/50/like--v1.png"
                            alt="like--v1"
                          />
                        )}
                      </div>{" "}
                      {/* <div className="likeCounter">
                      {data.find((item) => item.postId === post.id)
                        ?.totalLikes || 0}{" "}
                      beğeni
                    </div> */}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <ul className="pagination">
        <li className="page-item">
          <a href="#" className="page-link" onClick={prePage}>
            Previous
          </a>
        </li>
        {numbers.map((n, i) => (
          <li
            className={`page-item ${cuurentPage === n ? "activep" : ""}`}
            key={i}
          >
            <a href="#" className="page-link" onClick={() => changePage(n)}>
              {" "}
              {n}{" "}
            </a>
          </li>
        ))}

        <li className="page-item">
          <a href="#" className="page-link" onClick={nextPage}>
            Next
          </a>
        </li>
      </ul>
      <Footer />
    </div>
  );
};

export default Home;
